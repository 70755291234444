<template>
  <v-row v-if="loading">
    <v-col
      cols="12"
    >
      <div
        class="text-center"
      >
        <h2 class="primary--text mb-4">
          Loading, please wait ...
        </h2>
        <v-progress-circular
          indeterminate
          size="75"
          color="primary"
          rotate="0"
        ></v-progress-circular>
      </div>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col
      cols="12"
      md="8"
      sm="12"
    >
      <material-user-table
        :user-info="userInfo"
      ></material-user-table>
    </v-col>
    <v-col
      v-if="materialsData.length > 0"
      cols="12"
      sm="6"
      md="2"
    >
      <statistics-card-vertical-no-dots
        color="success"
        :icon="icons.mdiCurrencyUsd"
        :statistics="formatRevenue(soldRevenue)"
        stat-title="Total Sold"
        subtitle="All-time Revenue"
      ></statistics-card-vertical-no-dots>
    </v-col>
    <!--
      <v-col
      cols="12"
      md="8"
      sm="12"
    >
        <material-material-table :material-info="materialInfo"></material-material-table>
      </v-col>
    -->
    <v-col
      v-if="statisticsData.length > 0"
      cols="12"
      md="5"
      sm="12"
    >
      <material-statistics-card :statsdata="statisticsData"></material-statistics-card>
    </v-col>
    <v-col
      v-if="statisticsType.length > 0"
      cols="12"
      md="5"
      sm="12"
    >
      <material-sales-overview
        :statstype="statisticsType"
      ></material-sales-overview>
    </v-col>
  </v-row>
</template>

<script>
import StatisticsCardVerticalNoDots from '@/@core/components/statistics-card/StatisticsCardVerticalNoDots.vue'
import store from '@/store'
import { mdiCurrencyUsd } from '@mdi/js'
import { onUnmounted, ref } from 'vue'
import currency from 'currency.js'
import MaterialMaterialTable from './MaterialMaterialTable.vue'
import MaterialSalesOverview from './MaterialSalesOverview.vue'
import MaterialStatisticsCard from './MaterialStatisticsCard.vue'
import MaterialUserTable from './MaterialUserTable.vue'
// eslint-disable-next-line object-curly-newline

// import StatisticsCardLineChart from '@core/components/statistics-card/StatisticsCardLineChart.vue'
// import StatisticsCardRadialBarChart from '@core/components/statistics-card/StatisticsCardRadialBarChart.vue'

import materialStoreModule from '../../apps/material/materialStoreModule'

export default {
  components: {
    StatisticsCardVerticalNoDots,

    // StatisticsCardLineChart,
    // StatisticsCardRadialBarChart,
    MaterialStatisticsCard,
    MaterialSalesOverview,
    MaterialUserTable,
    MaterialMaterialTable,
  },
  setup() {
    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
    })
    const loading = ref(true)
    const materialsData = ref([])
    const soldRevenue = ref(0)
    const statisticsData = ref([
      {
        title: 'Published',
        total: 0,
      },
      {
        title: 'Sold',
        total: 0,
      },
      {
        title: 'Total',
        total: 0,
      },
    ])
    const statisticsDataType = ref([])
    const statisticsType = ref([])
    const userInfo = ref({})
    const materialInfo = ref([])

    const popMaterialInfo = () => {}

    const allNamesPopulated = () => {
      let allValid = true
      Object.keys(userInfo.value).forEach((k, idx) => {
        if (
          userInfo.value[k] === undefined ||
          userInfo.value[k].name === undefined ||
          userInfo.value[k].name === null ||
          userInfo.value[k].name.length === 0
        ) {
          allValid = false
        }
      })

      if (!allValid) {
        setTimeout(allNamesPopulated, 500)
      } else {
        popMaterialInfo()
        loading.value = false
      }
    }

    const fetchName = uid => {
      store
        .dispatch('app-material/getUserById', uid)
        .then(response => {
          userInfo.value[uid].name = response.data().fullname
          userInfo.value[uid].email = response.data().email
        })
        .catch(error => {
          console.log(error)
        })
    }

    const setStats = (data) => {
      materialsData.value = data

      materialsData.value.forEach((m, idx) => {
        // User Info
        if (userInfo.value[m.posted_by] === null || userInfo.value[m.posted_by] === undefined) {
          userInfo.value[m.posted_by] = {
            id: m.posted_by,
            name: '',
            email: '',
            total: 0,
            total_published: 0,
            total_sold: 0,
            amount_sold: 0,
          }
          fetchName(m.posted_by)
        }

        // Type
        const theTypeIdx = statisticsType.value.findIndex(t => t.name === m.type)
        if (theTypeIdx < 0) {
          statisticsType.value.push({
            name: m.type,
            count: 0,
          })
        } else {
          statisticsType.value[theTypeIdx].count += 1
        }

        // Published Count
        if (m.published) {
          statisticsData.value[0].total += 1
          userInfo.value[m.posted_by].total_published += 1
        }

        // Sold Count
        if (m.sold) {
          statisticsData.value[1].total += 1
          userInfo.value[m.posted_by].total_sold += 1

          soldRevenue.value += parseFloat(m.price)
          userInfo.value[m.posted_by].amount_sold += parseFloat(m.price)
        }
        statisticsData.value[2].total += 1
        userInfo.value[m.posted_by].total += 1
      })
      allNamesPopulated()
    }

    const fetchMaterialsFromDB = () => {
      store
        .dispatch('app-material/fetchMaterials')
        .then(response => {
          setStats(response)
        })
        .catch(error => {
          console.log(error)
          location.reload()
        })
    }

    const formatRevenue = revenue => currency(revenue, { precision: 2 }).format()

    const materials = localStorage.getItem('materials-list')
    if (materials === undefined || materials === null) {
      fetchMaterialsFromDB()
    } else {
      setStats(JSON.parse(materials))
    }
    

    return {
      soldRevenue,
      formatRevenue,
      materialsData,
      statisticsData,
      statisticsDataType,
      statisticsType,
      userInfo,
      materialInfo,
      loading,
      StatisticsCardVerticalNoDots,
      MaterialStatisticsCard,
      MaterialSalesOverview,
      MaterialUserTable,
      MaterialMaterialTable,
      icons: {
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
