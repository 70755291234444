<template>
  <v-card>
    <v-data-table
      v-model="selected"
      :headers="tableColumnHeaders"
      :items="userList"
      item-key="id"
      show-select
      hide-default-footer
      class="table-rounded"
    >
      <!-- trending header -->
      <template #[`header.status`]>
        <v-icon size="22">
          {{ icons.mdiTrendingUp }}
        </v-icon>
      </template>

      <!-- trending  -->
      <template #[`item.status`]="{item}">
        <v-avatar
          size="30"
          :color="item.statusColor"
          :class="`v-avatar-light-bg ${item.statusColor}--text`"
        >
          <v-icon
            size="18"
            :color="item.statusColor"
          >
            {{ item.status }}
          </v-icon>
        </v-avatar>
      </template>

      <!-- client -->
      <template #[`item.fullName`]="{item}">
        <div class="d-flex align-center">
          <!--<v-avatar
            :color="item.avatar ? '' : item.avatarColor"
            :class="`v-avatar-light-bg ${item.avatarColor}--text`"
            size="30"
          >
            <v-img
              v-if="item.avatar"
              :src="require(`@/assets/images/avatars/${item.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-medium"
            >{{ item.fullName.slice(0,2).toUpperCase() }}</span>
          </v-avatar>-->
          <div class="d-flex flex-column">
            <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.fullName }}</span>
            <span class="text-xs">{{ item.email }}</span>
          </div>
        </div>
      </template>

      <!-- total -->
      <template #[`item.amount_sold`]="{item}">
        ${{ item.amount_sold }}
      </template>

      <!-- Balance -->
      <template #[`item.balance`]="{item}">
        <span v-if="typeof item.balance === 'number'"> ${{ item.balance }}</span>
        <v-chip
          v-else
          small
          :color="chipColor[item.balance]"
          :class="`v-chip-light-bg ${chipColor[item.balance]}--text font-weight-semibold`"
        >
          {{ item.balance }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref } from 'vue'
// eslint-disable-next-line object-curly-newline
import { mdiTrendingUp } from '@mdi/js'

export default {
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const selected = ref([])
    const chipColor = {
      Paid: 'success',
      Unpaid: 'error',
    }

    const userList = ref([])

    const tableColumnHeaders = [
      {
        text: '#ID',
        align: 'start',
        value: 'id',
      },
      { text: 'CLIENT', value: 'fullName' },
      { text: 'PUBLISHED', value: 'total_published' },
      { text: 'SOLD', value: 'total_sold' },
      { text: 'AMOUNT SOLD', value: 'amount_sold' },
    ]

    Object.keys(props.userInfo).forEach((key, idx) => {
      const user = {
        id: key,
        fullName: props.userInfo[key].name,
        email: props.userInfo[key].email,
        total: props.userInfo[key].total,
        total_published: props.userInfo[key].total_published,
        total_sold: props.userInfo[key].total_sold,
        amount_sold: props.userInfo[key].amount_sold,
      }
      userList.value.push(user)
    })

    return {
      selected,
      tableColumnHeaders,
      userList,
      chipColor,
      icons: {
        mdiTrendingUp,
      },
    }
  },
}
</script>
